/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import { Typography, VariableContent, Link } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

import CountdownTimer from '../components/Countdown'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: ' Not Found ',
          description: ' Not Found ',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'nofollow,noindex',
        },
        path: '/404',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: ' Not Found ',
    defaultRobots: 'nofollow,noindex',

    main: (
      <>
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h1">Page Not Found</Typography>
              <Typography variant="body">
                We're sorry but this page doesn't exist.{' '}
                <Link to="/">Return to homepage</Link>.
              </Typography>
            </>
          }
        ></VariableContent>
        <CountdownTimer />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
